<template>
  <div>
    <modal
      :clickToClose="false"
      :delay="0"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :maxHeight="100"
      :maxWidth="900"
      :classes="['v--modal']"
      class="pozice-modal"
      width="95%"
      name="pozice-modal"
    >
      <div class="pozice-modal__container">
        <div class="admin-decide__text">
          <h1>Vyberte svou roli pro toto přihlášení.</h1>
          <router-link to="/admin">
            <button class="admin-decide__text__button">Administrátor</button>
          </router-link>
          <router-link to="/">
            <button class="admin-decide__text__button">Uživatel</button>
          </router-link>
        </div>
      </div>
    </modal>

    <div class="admin-decide" :class="{'blur-content': showModal}">
      <HeaderUser />
    </div>
  </div>
</template>

<script>
import HeaderUser from "./components/HeaderUser.vue";

export default {
  name: "AdminDecide",
  components: {
    HeaderUser
  },
  data() {
    return {
      role: [],
      katedry: [],
      errors: [],
      pozice: null,
      katedra: null
    };
  },

  methods: {
    showModal() {
      this.$modal.show("pozice-modal", {
        height: "auto"
      });
    }
  },
  mounted() {
    this.$gtag.pageview({
      page_path: "/admin-decide"
    });
    this.showModal();
  }
};
</script>
